import React, { Component } from 'react';

class Rodape extends Component {

  constructor(props) {
    super(props);
  }

  data_atual = new Date();

  render() {
    return (
      <footer className="footer-3 footer-topbar light page-section-pt">
        <div className="container">
          <div className="row top">
            <div className="col-lg-3 col-md-12">
              <img
                className="img-fluid"
                id="logo_footer_dark"
                src="images/logo.png"
                alt=""
              />
            </div>

            {/* Whatsapp */}
            {/* <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">  */}
            {/*<a  className='whatsappFlutuante' href={'https://api.whatsapp.com/send?text=Olá,vi um anúncio em seu site e gostaria de saber mais sobre o veículo?&phone=55'+retiraMascara(this.props.revenda.petelefone2, "link")} target='_blank' >
              <i className="fa fa-whatsapp"></i>
            </a>*/}

            <div className="col-lg-4 col-md-12">
              <div className="social text-lg-right text-center">
                <ul>
                  <li>
                    <a href={ this.props.revenda.facebook_link } target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook-square"></i></a>
                  </li>
                  <li>
                    <a href={ this.props.revenda.instagram_link } target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram" target="_blank"></i></a>
                  </li>
                  {/*<li>
                    <a target="_blank" href={ 'https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55'+retiraMascara(this.props.revenda.petelefone2, "link") }><i className="fa fa-whatsapp"></i></a>
                  </li>*/}
                  {/* <li>
                    <a target="_blank" href={ 'https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55'+retiraMascara(this.props.revenda.petelefone3, "link") }><i className="fa fa-whatsapp"></i></a>
                  </li> */}
                  <li>
                    <a target="_blank" href={ this.props.revenda.mapa_link } className="wow slideInLeft" rel="noopener noreferrer" ><i className="fa fa-map-marker"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr />
            </div>
          </div>
          <div className="row" style={{display:"flex", justifyContent: "center", textAlign: "center"}}>
            <div className="col-lg-5 col-md-12">
              <div className="about-content">
                <h6 className="text-sobrenos">Sobre nós</h6>
                <p className="text-sobrenos-info">
                Bem-vindo à Premium Multimarcas, onde o luxo e a qualidade se encontram. Somos apaixonados por carros e dedicados a oferecer uma experiência excepcional aos nossos clientes. Com uma seleção cuidadosamente curada de veículos das principais marcas, garantimos que cada carro em nosso showroom atenda aos mais altos padrões de desempenho e elegância.
                </p>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <h6 className="text-sobrenos">Informações da loja</h6>
              <div className="usefull-link">
                <div className="row">
                  <div className="col-md-4 footer_info">
                    <ul className="text-sobrenos-info">
                      <li>
                        <a target="_blank" href={ this.props.revenda.mapa_link } className="wow slideInLeft" rel="noopener noreferrer" >
                          { this.props.revenda.peendereco_fiscal } - { this.props.revenda.peendereco_fiscal_numero } - { this.props.revenda.pebairro_fiscal } - { this.props.revenda.pecidade } - { this.props.revenda.peuf }
                        </a>
                      </li>
                     {/* <li>
                        <a href={ "tel:" + retiraMascara(this.props.revenda.petelefone1, "link") } >
                          <i className="fa fa-phone"></i>
                          { retiraMascara(this.props.revenda.petelefone1) }
                        </a>
                      </li>*/}
                      {/* <li>
                      <a target="_blank" href="https://linktr.ee/">
                        <i className="fa fa-whatsapp"></i>
                        WHATSAPPs
                        </a>
                      </li> */}
                      {/*<li>
                        <a target="_blank" href={ 'https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55'+retiraMascara(this.props.revenda.petelefone2, "link") }>
                          <i className="fa fa-whatsapp"></i>
                          { retiraMascara(this.props.revenda.petelefone2) }
                          </a>
                      </li>*/}
                     {/* <li>
                        <a target="_blank" href={ 'https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55'+retiraMascara(this.props.revenda.petelefone3, "link") }>
                          <i className="fa fa-whatsapp"></i>
                          { retiraMascara(this.props.revenda.petelefone3) }
                          </a>
                      </li>*/}
                      <li>
                        <i className="fa fa-envelope-o" style={{marginRight: "10px"}}> </i>
                        <a href={ "mailto:" + this.props.revenda.peemail }>{ this.props.revenda.peemail }</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row" style={{display:"flex", justifyContent:"center"}}>
              <div className="col-lg-6 col-md-12">
                <div className="text-lg-left text-center">
                  <p style={{textAlign:"center"}}>
                    © Copyright 2024 Premium Multimarcas | Todos os direitos reservados.
                    <a href="https://intermedioveiculos.com.br/" target="HKe2fZ02jqcU0ixfKIxz"
                    > Intermédio Veículos Marketing Digital</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

    );
  }
}

function retiraMascara(value, type = "texto") {
  var numero = '';
  if (value !== undefined) {
    numero = value.replace('_', '');
    if (type === "link") {
      numero = numero.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    }
  }
  return numero;
}

export default Rodape;